/* eslint-disable no-use-before-define */
/* eslint-disable no-redeclare */
/* eslint-disable no-extend-native */
/* eslint-disable eqeqeq */
export const substr = (str, start, end) => {
  if (str == null) {
    return "";
  } else {
    return str.substring(start, start + end);
  }
};
export const tgl_indo = (tgl) => {
  if (tgl == "0000-00-00") {
    return "-";
  } else {
    let tanggal = substr(tgl, 8, 2);
    let bulan = get_bulan(substr(tgl, 5, 2));
    let tahun = substr(tgl, 0, 4);
    return tanggal + " " + bulan + " " + tahun;
  }
};
export const format_tgl = (tgl, indo = !0) => {
  if (substr(tgl, 2, 1) == "-") {
    let tanggal = substr(tgl, 0, 2);
    let bulan = substr(tgl, 3, 2);
    let tahun = substr(tgl, 6, 4);
    return tahun + "-" + bulan + "-" + tanggal;
  } else if (substr(tgl, 4, 1) == "-") {
    let tahun = substr(tgl, 0, 4);
    let bulan = substr(tgl, 5, 2);
    let tanggal = substr(tgl, 8, 2);
    let waktu = substr(tgl, 11, 8);
    return indo
      ? tanggal + " " + get_bulan(bulan) + " " + tahun + ", " + set_waktu(waktu)
      : tanggal + "-" + bulan + "-" + tahun;
  }
};

export const set_waktu = (wkt) => {
  let jam = substr(wkt, 0, 2);
  let menit = substr(wkt, 3, 2);
  let AMPM = "PM";

  if (jam < 12) {
    AMPM = "AM";
    if (jam == 0) jam = 12;
  } else {
    if (jam != 12) jam = jam - 12;
  }

  return jam + ":" + menit + " " + AMPM;
};

export const get_hari = (day) => {
  if (parseInt(day) == 0) return "Ahad";
  else if (parseInt(day) == 1) return "Senin";
  else if (parseInt(day) == 2) return "Selasa";
  else if (parseInt(day) == 3) return "Rabu";
  else if (parseInt(day) == 4) return "Kamis";
  else if (parseInt(day) == 5) return "Jumat";
  else if (parseInt(day) == 6) return "Sabtu";
  else return day;
};

export const get_bulan = (bln) => {
  if (parseInt(bln) == 1) return "Januari";
  else if (parseInt(bln) == 2) return "Februari";
  else if (parseInt(bln) == 3) return "Maret";
  else if (parseInt(bln) == 4) return "April";
  else if (parseInt(bln) == 5) return "Mei";
  else if (parseInt(bln) == 6) return "Juni";
  else if (parseInt(bln) == 7) return "Juli";
  else if (parseInt(bln) == 8) return "Agustus";
  else if (parseInt(bln) == 9) return "September";
  else if (parseInt(bln) == 10) return "Oktober";
  else if (parseInt(bln) == 11) return "November";
  else if (parseInt(bln) == 12) return "Desember";
  else return bln;
};

export const rupiah = (angka, kurs = "") => {
  if (kurs) {
    return kurs + " " + parseInt(angka).formatMoney(0, ".", ",");
  }

  return "Rp" + parseInt(angka).formatMoney(0, ",", ".") + ",-";
};

Number.prototype.formatMoney = function (c, d, t) {
  var n = this,
    c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};

export const formatAngka = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
